



























































import { Component, Prop, Vue } from 'vue-property-decorator';
import WWLogo from '../inline-svg/WWLogo.vue';

@Component({
  components: {
    WWLogo,
  },
})
export default class Footer extends Vue {}
