


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HeroFeatures extends Vue {
  @Prop() private productHero!: boolean;
}
