import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    services: [],
  },
  mutations: {
    setAuthServices(state, servicesObject) {
      state.services = servicesObject;
    },
  },
  getters: {
    twitchAuthWW: (state: any) => {
      const WWservice = state.services.filter((service: any) => service.identifier === 'ww')[0];
      return WWservice.providers.filter((provider: any) => provider.identifier === 'twitch')[0];
    },
  },
  actions: {
    async initAuthServices({commit}) {
      const response = await fetch(`/api/auth/products`);
      if (response) {
        commit('setAuthServices', await response.json());
      } else {
        throw new Error('Unable to obtain services details from Auth Service.');
      }
    },
  },
});
