




















import { Component, Prop, Vue } from 'vue-property-decorator';
import { getEvents } from '@/services/forum';

@Component
export default class EventsList extends Vue {
  @Prop() private title!: string;
  @Prop() private bgColor!: string;
  private events = {};

  private async mounted() {
    this.events = await getEvents();
  }

}
