










import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import HomeHero from './HomeHero.vue';
import SupportWW from '@/components/SupportWW.vue';
import CCLive from '@/components/CCLive.vue';
import HomeProducts from './HomeProducts.vue';
import EventsList from '@/components/EventsList.vue';

@Component({
  components: {
    Header,
    Footer,
    HomeHero,
    SupportWW,
    CCLive,
    HomeProducts,
    EventsList,
  },
})
export default class Home extends Vue {}
