import axios from 'axios';
import moment from 'moment';

const CONTACT_STRING = 'contact us at privacy@warp.world.';

export const getFAQ = (id: number) => {
  return axios.get(`https://api.warp.world/cms?id=${id}`).then((response) => {
    const data: string[] = response.data.data.content.split('\n<hr>\n');
    const questions: any[] = [];

    data.forEach((item: string) => {
      item = item.replace(/\n/g, '');
      const question = item.substring(0, item.indexOf('</h1>') + 5)
        .replace('<h1>', '')
        .replace('</h1>', '');

      const answer = item.substring(item.indexOf('</h1>') + 5);

      // strip the "i have another question" questions
      if (question.toLowerCase().indexOf('another question') === -1) {
        questions.push({ question, answer});
      }
    });

    return questions;
  });
};

export const getPrivacyPolicy = async (): Promise<string> => {
  try {
    const response = await axios.get('https://api.warp.world/cms?id=4778');

    return response.data.data.content
      .replace(/<br>/g, '</p><p>')
      .replace(CONTACT_STRING, 'contact us at <a href="mailto:privacy@warp.world">privacy@warp.world</a>.');
  } catch (e) {
    throw new Error(e);
  }
};

export const getTerms = async (): Promise<string> => {
  try {
    const response = await axios.get('https://api.warp.world/cms?id=4779');

    return response.data.data.content
      .replace(/<br>/g, '</p><p>')
      .replace(CONTACT_STRING, 'contact us at <a href="mailto:privacy@warp.world">privacy@warp.world</a>.');
  } catch (e) {
    throw new Error(e);
  }
};

export const getEvents = async (): Promise<string> => {
  try {
    const response = await axios.get('https://api.warp.world/cms?id=4819');
    const parsed = JSON.parse(response.data.data.content.replace(/<[^>]*>/g, ''));

    const eventsFilteredByDate = parsed.filter((event: any) => {
      return moment(event.date, 'MMMM DD, YYYY').isAfter(moment().subtract(4, 'days'));
    });

    return eventsFilteredByDate;

  } catch (e) {
    throw new Error(e);
  }
};

