import Vue from 'vue';
import Router from 'vue-router';

import Home from './views/home/Home.vue';
import Layout from './components/Layout.vue';

import store from './store';

Vue.use(Router);

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      component: Layout,
      async beforeEnter(to, from, next) {
        await store.dispatch('initAuthServices');
        next();
      },
      children: [
        {
          path: '/',
          name: 'home',
          component: Home,
        },
        {
          path: '/press',
          name: 'press',
          component: () => import(/*  webpackChunkName: "press" */ './views/Press.vue'),
        },
        {
          path: '/services/crowd-control',
          name: 'service-cc',
          component: () => import(/*  webpackChunkName: "service-cc" */ './views/services/ServiceCC.vue'),
        },
        {
          path: '/services/1upcoin',
          name: 'service-1uc',
          component: () => import(/*  webpackChunkName: "service-1uc" */ './views/services/Service1UC.vue'),
        },
        {
          path: '/services/turnip-exchange',
          name: 'service-turnip-exchange',
          component: () => import(/*  webpackChunkName: "service-te" */ './views/services/ServiceTE.vue'),
        },
        {
          path: '/services/multi-queue',
          redirect: '/services/multiqueue',
        },
        {
          path: '/services/multiqueue',
          name: 'service-mq',
          component: () => import(/*  webpackChunkName: "service-mq" */ './views/services/ServiceMQ.vue'),
        },
        {
          path: '/support',
          name: 'support',
          component: () => import(/*  webpackChunkName: "privacy" */ './views/Support.vue'),
        },
        {
          path: '/content',
          name: 'content',
          component: () => import(/*  webpackChunkName: "privacy" */ './views/Content.vue'),
        },
        {
          path: '/team',
          name: 'team',
          component: () => import(/*  webpackChunkName: "privacy" */ './views/Team.vue'),
        },
        {
          path: '/privacy',
          name: 'privacy',
          component: () => import(/*  webpackChunkName: "privacy" */ './views/Privacy.vue'),
        },
        {
          path: '/terms',
          name: 'terms',
          component: () => import(/*  webpackChunkName: "terms-conditions" */ './views/Terms.vue'),
        },
        {
          path: '/login-error',
          name: 'login-error',
          component: () => import(/*  webpackChunkName: "redirect-error" */ './views/RedirectError.vue'),
        },
        {
          path: '/logout',
        },
      ],
    },
    { path: '*', redirect: '/' },
  ],
});

export default router;
