












































import { Component, Vue } from 'vue-property-decorator';
import { getActiveCCSessions } from '@/services/crowd-control';
import { Channel} from '../services/crowd-control';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

@Component({components: {Swiper, SwiperSlide}})
export default class CCLive extends Vue {

  private swiperOptions = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    initialSlide: 0,
    slidesPerView: 'auto',
    mousewheel: true,
    keyboard: {
      enabled: true,
    },
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows : true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };


  private channels: Array<[string, Channel]> = [];
  private activeSlide: number = -1;

  private async mounted() {
    // get the first set of live channels
    await this.getLiveChannels();

    // set up interval to get fresh channels every few minutes
    setInterval(async () => {
      await this.getLiveChannels();
    }, 60000 * 5);

  }

  private updateActiveSlide() {
    this.activeSlide = this.$refs.mySwiper ? (this.$refs.mySwiper as any).$swiper.activeIndex : -1;
  }

  private getSwiper() {
    return this.$refs.mySwiper ? (this.$refs.mySwiper as any).$swiper : undefined;
  }

  get windowLocation() {
    return window.location;
  }

  private async getLiveChannels() {
    const liveChannels = await getActiveCCSessions();
    this.channels = Object.entries(liveChannels.channels);
    this.swiperOptions.initialSlide = Math.floor(Object.keys(liveChannels.channels).length / 2) || 0;
    this.updateActiveSlide();
  }

}
