import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/tailwind.css';
import '@/assets/css/global.scss';
import Component from 'vue-class-component';
import ScrollTo from 'vue-scrollto';
import VueCookies from 'vue-cookies';

import VueTippy, { TippyComponent } from 'vue-tippy';
import 'tippy.js/themes/light.css';

Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);

Vue.use(ScrollTo);
Vue.use(VueCookies);
Vue.config.productionTip = false;

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
