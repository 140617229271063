

























import { Component, Prop, Vue } from 'vue-property-decorator';
import Header from '@/components/Header.vue';

@Component({
  components: {
    Header,
  },
})
export default class HeroIntro extends Vue {
  private heroTitle = '';
  private heroSubtext = '';
  private primaryAction = {};
  private secondaryAction = {};

  @Prop() private size!: string;
  @Prop() private title!: string;
  @Prop() private subtext!: string;
  @Prop() private primary?: any;
  @Prop() private secondary?: any;

  private mounted() {
    this.heroTitle = this.title;
    this.heroSubtext = this.subtext;
    this.primaryAction = this.primary || null;
    this.secondaryAction = this.secondary || null;
  }

}
