import axios from 'axios';

export interface LiveChannels {
  channels: {
    [key: string]: Channel;
  };
}

export interface Channel {
  name: string;
  live: boolean;
  title: string;
  viewerCount: string;
  game: string;
  match: boolean;
}

export const getActiveCCSessions = async () => {
  const response = await axios.get('/api/crowd-control/active-sessions');
  return response.data;
};

export const getStats = async () => {
  const response = await axios.get('/api/crowd-control/stats');
  return response.data;
};
