








































































































































































import { Component, Vue } from 'vue-property-decorator';
import WWLogo from '../inline-svg/WWLogo.vue';
import ChevronDown from '../inline-svg/ChevronDown.vue';

@Component({
  components: {
    WWLogo,
    ChevronDown,
  },
})
export default class Header extends Vue {
  private showMobileModal = false;

  get WWCookie() {
    return this.$cookies.get('ww-user') ? true : false; // typescript lies, this.$cookies is fine
  }

  get appRedirect() {
    return `${location.protocol}//${location.host}/app`;
  }

  get oauthRedirect() {
    const loginURL = process.env.VUE_APP_LOGIN_URL;
    const isStaging = location.host.includes('staging');

    const redirectHost = `${loginURL ? loginURL : `https://${isStaging ? 'staging-' : ''}auth.warp.world`}`;
    const redirectUrl = `redirectUrl=${location.protocol}//${location.host}/app?auth=ww`;

    return `${redirectHost}/api/auth/oauth?provider=twitch&state=${this.generateStateKey()}&${redirectUrl}`;
  }

  private generateStateKey() {
    const keyPart1 = Math.random().toString(36).substring(2, 15);
    const keyPart2 = Math.random().toString(36).substring(2, 15);
    localStorage.setItem('WWAuthState', `twitch-${keyPart1}${keyPart2}`);
    return `${keyPart1}${keyPart2}`;
  }

  private showModal() {
    this.showMobileModal = !this.showMobileModal;
    const body = document.querySelector('body');
    if (body) {
      body.classList.add('overflow-hidden');
    }
  }

  private closeModal() {
    if (this.showMobileModal) {
      this.showMobileModal = !this.showMobileModal;
    }
    const body = document.querySelector('body');
    if (body) {
      body.classList.remove('overflow-hidden');
    }
  }
}
