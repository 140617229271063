













































import { Component, Vue } from 'vue-property-decorator';
import WarpBarLogo from '@/inline-svg/WarpBarLogo.vue';
import CCLogo from '@/inline-svg/CCLogo.vue';
import MQLogo from '@/inline-svg/MQLogo.vue';

@Component({
  components: {
    WarpBarLogo,
    CCLogo,
    MQLogo,
  },
})
export default class HomeProducts extends Vue {}
