

























import { Component, Vue } from 'vue-property-decorator';
import HeroFeatures from '@/components/HeroFeatures.vue';
import HeroIntro from '@/components/HeroIntro.vue';

@Component({
  components: {
    HeroFeatures,
    HeroIntro,
  },
})
export default class HomeHero extends Vue {

  private secondary = {
    buttonText: `Login to get started`,
    type: 'link',
    to: '/login',
  };

  // private primary = {
  //   buttonText: `Meet the Team`,
  //   type: 'link',
  //   to: '/team',
  // };
}
